// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-patient-js": () => import("./../../../src/pages/admin/patient.js" /* webpackChunkName: "component---src-pages-admin-patient-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dentistry-childrens-dentistry-js": () => import("./../../../src/pages/dentistry/childrens-dentistry.js" /* webpackChunkName: "component---src-pages-dentistry-childrens-dentistry-js" */),
  "component---src-pages-dentistry-cosmetic-dentistry-js": () => import("./../../../src/pages/dentistry/cosmetic-dentistry.js" /* webpackChunkName: "component---src-pages-dentistry-cosmetic-dentistry-js" */),
  "component---src-pages-dentistry-general-dentistry-js": () => import("./../../../src/pages/dentistry/general-dentistry.js" /* webpackChunkName: "component---src-pages-dentistry-general-dentistry-js" */),
  "component---src-pages-dentistry-index-js": () => import("./../../../src/pages/dentistry/index.js" /* webpackChunkName: "component---src-pages-dentistry-index-js" */),
  "component---src-pages-dentistry-invisalign-2-js": () => import("./../../../src/pages/dentistry/invisalign-2.js" /* webpackChunkName: "component---src-pages-dentistry-invisalign-2-js" */),
  "component---src-pages-dentistry-invisalign-js": () => import("./../../../src/pages/dentistry/invisalign.js" /* webpackChunkName: "component---src-pages-dentistry-invisalign-js" */),
  "component---src-pages-dentistry-testimonials-js": () => import("./../../../src/pages/dentistry/testimonials.js" /* webpackChunkName: "component---src-pages-dentistry-testimonials-js" */),
  "component---src-pages-earth-day-js": () => import("./../../../src/pages/earth-day.js" /* webpackChunkName: "component---src-pages-earth-day-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leave-a-review-js": () => import("./../../../src/pages/leave-a-review.js" /* webpackChunkName: "component---src-pages-leave-a-review-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-patient-forms-js": () => import("./../../../src/pages/patient-forms.js" /* webpackChunkName: "component---src-pages-patient-forms-js" */),
  "component---src-pages-patient-registration-js": () => import("./../../../src/pages/patient-registration.js" /* webpackChunkName: "component---src-pages-patient-registration-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-the-competition-js": () => import("./../../../src/pages/the-competition.js" /* webpackChunkName: "component---src-pages-the-competition-js" */),
  "component---src-pages-the-invisalign-day-js": () => import("./../../../src/pages/the-invisalign-day.js" /* webpackChunkName: "component---src-pages-the-invisalign-day-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

